import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _76c42547 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _00d3615e = () => interopDefault(import('../pages/get-app.vue' /* webpackChunkName: "pages/get-app" */))
const _52d2e3c8 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _492a715b = () => interopDefault(import('../pages/listings/index.vue' /* webpackChunkName: "pages/listings/index" */))
const _256e7bd0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _ef7a335a = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _091f8608 = () => interopDefault(import('../pages/login/code.vue' /* webpackChunkName: "pages/login/code" */))
const _551bf29a = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _a97d7378 = () => interopDefault(import('../pages/onboarding.vue' /* webpackChunkName: "pages/onboarding" */))
const _e8adec72 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _1b12947c = () => interopDefault(import('../pages/onboarding/assign-agent.vue' /* webpackChunkName: "pages/onboarding/assign-agent" */))
const _b0c7d8c6 = () => interopDefault(import('../pages/onboarding/become-agent/index.vue' /* webpackChunkName: "pages/onboarding/become-agent/index" */))
const _809f1642 = () => interopDefault(import('../pages/onboarding/get-app.vue' /* webpackChunkName: "pages/onboarding/get-app" */))
const _f4b2c642 = () => interopDefault(import('../pages/onboarding/invite-agent/index.vue' /* webpackChunkName: "pages/onboarding/invite-agent/index" */))
const _1d703f07 = () => interopDefault(import('../pages/onboarding/become-agent/thank-you.vue' /* webpackChunkName: "pages/onboarding/become-agent/thank-you" */))
const _4beb60c8 = () => interopDefault(import('../pages/onboarding/invite-agent/invited.vue' /* webpackChunkName: "pages/onboarding/invite-agent/invited" */))
const _218f6fe0 = () => interopDefault(import('../pages/agents/404.vue' /* webpackChunkName: "pages/agents/404" */))
const _1cdcc0af = () => interopDefault(import('../pages/profile/dynamic-links/index.vue' /* webpackChunkName: "pages/profile/dynamic-links/index" */))
const _3e68bebd = () => interopDefault(import('../pages/profile/likes.vue' /* webpackChunkName: "pages/profile/likes" */))
const _4db8cb9c = () => interopDefault(import('../pages/profile/settings.vue' /* webpackChunkName: "pages/profile/settings" */))
const _561ea616 = () => interopDefault(import('../pages/profile/stuff.vue' /* webpackChunkName: "pages/profile/stuff" */))
const _6866f7bd = () => interopDefault(import('../pages/profile/dynamic-links/new.vue' /* webpackChunkName: "pages/profile/dynamic-links/new" */))
const _5bc6d8b9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8fce7022 = () => interopDefault(import('../pages/profile/clients/_node_id.vue' /* webpackChunkName: "pages/profile/clients/_node_id" */))
const _c42d681c = () => interopDefault(import('../pages/profile/dynamic-links/_id/edit.vue' /* webpackChunkName: "pages/profile/dynamic-links/_id/edit" */))
const _4c8f3396 = () => interopDefault(import('../pages/agents/_slug/index.vue' /* webpackChunkName: "pages/agents/_slug/index" */))
const _47d96438 = () => interopDefault(import('../pages/legal/_doc.vue' /* webpackChunkName: "pages/legal/_doc" */))
const _5ae356ea = () => interopDefault(import('../pages/lists/_id/index.vue' /* webpackChunkName: "pages/lists/_id/index" */))
const _dfca4e0c = () => interopDefault(import('../pages/notifications/_id.vue' /* webpackChunkName: "pages/notifications/_id" */))
const _02981dd6 = () => interopDefault(import('../pages/s/_slug.vue' /* webpackChunkName: "pages/s/_slug" */))
const _077ec96e = () => interopDefault(import('../pages/verify-email/_code.vue' /* webpackChunkName: "pages/verify-email/_code" */))
const _db86c448 = () => interopDefault(import('../pages/agents/_slug/listings.vue' /* webpackChunkName: "pages/agents/_slug/listings" */))
const _3571941b = () => interopDefault(import('../pages/listings/_listing_id/presented-by/_presenting_agent_user_id/index.vue' /* webpackChunkName: "pages/listings/_listing_id/presented-by/_presenting_agent_user_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _76c42547,
    name: "contact"
  }, {
    path: "/get-app",
    component: _00d3615e,
    name: "get-app"
  }, {
    path: "/home",
    component: _52d2e3c8,
    name: "home"
  }, {
    path: "/listings",
    component: _492a715b,
    name: "listings"
  }, {
    path: "/login",
    component: _256e7bd0,
    children: [{
      path: "",
      component: _ef7a335a,
      name: "login"
    }, {
      path: "code",
      component: _091f8608,
      name: "login-code"
    }]
  }, {
    path: "/logout",
    component: _551bf29a,
    name: "logout"
  }, {
    path: "/onboarding",
    component: _a97d7378,
    children: [{
      path: "",
      component: _e8adec72,
      name: "onboarding"
    }, {
      path: "assign-agent",
      component: _1b12947c,
      name: "onboarding-assign-agent"
    }, {
      path: "become-agent",
      component: _b0c7d8c6,
      name: "onboarding-become-agent"
    }, {
      path: "get-app",
      component: _809f1642,
      name: "onboarding-get-app"
    }, {
      path: "invite-agent",
      component: _f4b2c642,
      name: "onboarding-invite-agent"
    }, {
      path: "become-agent/thank-you",
      component: _1d703f07,
      name: "onboarding-become-agent-thank-you"
    }, {
      path: "invite-agent/invited",
      component: _4beb60c8,
      name: "onboarding-invite-agent-invited"
    }]
  }, {
    path: "/agents/404",
    component: _218f6fe0,
    name: "agents-404"
  }, {
    path: "/profile/dynamic-links",
    component: _1cdcc0af,
    name: "profile-dynamic-links"
  }, {
    path: "/profile/likes",
    component: _3e68bebd,
    name: "profile-likes"
  }, {
    path: "/profile/settings",
    component: _4db8cb9c,
    name: "profile-settings"
  }, {
    path: "/profile/stuff",
    component: _561ea616,
    name: "profile-stuff"
  }, {
    path: "/profile/dynamic-links/new",
    component: _6866f7bd,
    name: "profile-dynamic-links-new"
  }, {
    path: "/",
    component: _5bc6d8b9,
    name: "index"
  }, {
    path: "/profile/clients/:node_id?",
    component: _8fce7022,
    name: "profile-clients-node_id"
  }, {
    path: "/profile/dynamic-links/:id?/edit",
    component: _c42d681c,
    name: "profile-dynamic-links-id-edit"
  }, {
    path: "/agents/:slug",
    component: _4c8f3396,
    name: "agents-slug"
  }, {
    path: "/legal/:doc?",
    component: _47d96438,
    name: "legal-doc"
  }, {
    path: "/lists/:id",
    component: _5ae356ea,
    name: "lists-id"
  }, {
    path: "/notifications/:id?",
    component: _dfca4e0c,
    name: "notifications-id"
  }, {
    path: "/s/:slug?",
    component: _02981dd6,
    name: "s-slug"
  }, {
    path: "/verify-email/:code?",
    component: _077ec96e,
    name: "verify-email-code"
  }, {
    path: "/agents/:slug?/listings",
    component: _db86c448,
    name: "agents-slug-listings"
  }, {
    path: "/listings/:listing_id/presented-by/:presenting_agent_user_id",
    component: _3571941b,
    name: "listings-listing_id-presented-by-presenting_agent_user_id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
