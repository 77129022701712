// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../icon_love_default.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../icon_love_on.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../icon_notification_default.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../icon_notification_on.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../icon_share_default.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../icon_share_blocked.svg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../icon_ellipsis_default.svg");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../icon_love_hover.svg");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../icon_notification_hover.svg");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../icon_share_hover.svg");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../icon_ellipsis_hover.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actions[data-v-095e86bf]{display:flex;flex-direction:row;justify-content:flex-start;align-items:center;margin-right:-0.3125rem}.actions a[data-v-095e86bf]{display:flex;justify-content:center;align-items:center;width:2.125rem;height:2.125rem;padding:.3125rem;cursor:pointer}.actions a[data-v-095e86bf]::before{display:block;content:\"\";width:100%;height:100%;background-position:center;background-size:contain;background-repeat:no-repeat}.actions a.love[data-v-095e86bf]::before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.actions a.love.active[data-v-095e86bf]::before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.actions a.subscribe[data-v-095e86bf]::before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.actions a.subscribe.active[data-v-095e86bf]::before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.actions a.share[data-v-095e86bf]::before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.actions a.share.blocked[data-v-095e86bf]{pointer-events:none}.actions a.share.blocked[data-v-095e86bf]::before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}.actions a.more[data-v-095e86bf]{border-radius:100%}.actions a.more[data-v-095e86bf]::before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ")}@media(hover: hover){.actions a:not(.active).love[data-v-095e86bf]:hover::before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ")}.actions a:not(.active).subscribe[data-v-095e86bf]:hover::before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ")}.actions a.share[data-v-095e86bf]:hover::before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ")}.actions a.more[data-v-095e86bf]:hover::before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ")}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
