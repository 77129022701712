
import { Vue, Component } from "vue-property-decorator";

import { BrokerListListingForbiddenError, ListingUnavailableError, VowTermsOfUseAgreementRequiredError } from "../misc/interfaces";
import VowTermsOfUse from "@/components/vow-terms-of-use.vue";

import AgentByIdQuery from "@/apollo/AgentByIdQuery.graphql";
import { AgentByIdQueryVariables, AgentByIdQuery as AgentByIdResult } from "@/gql-typings/AgentByIdQuery";
import { GraphQLError } from "graphql";
import { errorIsVowTermsOfUseAgreementRequiredError } from "~/misc/graphql-error-is-vow-tou-error";
import { errorIsListingUnavailableError } from "~/misc/graphql-error-is-listing-unavailable-error";
import { Getter, State } from "vuex-class";
import { RootState } from "~/store";
import { ShortListingFragment } from "~/gql-typings/ShortListingFragment";
import { LongListingFragment } from "~/gql-typings/LongListingFragment";
import { ViewerNotifications_notifications_edges_node_AgentDidGuessListingClosePriceNotification_listing as ListingChangeNotificationListingNode } from "~/gql-typings/ViewerNotifications";
import { errorIsBrokerListListingForbiddenError } from "~/misc/graphql-error-is-broker-list-forbidden-error";
import ConnectToProvidingAgentMutation from "@/apollo/ConnectToProvidingAgentMutation.graphql";
import {ConnectToProvidingAgentVariables, ConnectToProvidingAgent as ConnectToProvidingAgentResult } from "@/gql-typings/ConnectToProvidingAgent";

@Component({})
export default class ListingWithVowErrorMixin extends Vue {
	@State("viewer") viewer!: RootState["viewer"] | null;
	@Getter("agent") agent!: RootState["assignedAgent"] | null;

	listing!: ShortListingFragment | ShortListingFragment & LongListingFragment | ListingChangeNotificationListingNode | null;
	// TODO: can this be not-nullable?
	// NOTE: apparently, the error when it's passed around, is not an instanceof GraphQLError?
	listingError!: GraphQLError | null;

	errorIsVOWError(e: any): e is VowTermsOfUseAgreementRequiredError {
		return errorIsVowTermsOfUseAgreementRequiredError(e);
	}

	errorIsUnavailableError(e: any): e is ListingUnavailableError {
		return errorIsListingUnavailableError(e);
	}

	errorIsBrokerListListingForbiddenError(e: any): e is BrokerListListingForbiddenError {
		return errorIsBrokerListListingForbiddenError(e);
	}

	errorIsListingLatLonError(e: any): e is VowTermsOfUseAgreementRequiredError | BrokerListListingForbiddenError {
		return this.errorIsVOWError(e) || this.errorIsBrokerListListingForbiddenError(e);
	}

	async onListingErrorClick(event: MouseEvent) {
		event.preventDefault();

		const {listingError: error} = this;

		if (!error) {
			throw new Error("Missing error");
		}

		if (this.errorIsVOWError(error)) {
			if (!this.viewer) {
				await this.$router.push({
					path: '/login',
					query: {
						redirect: this.$route.fullPath
					}
				});

				return;
			}

			await this.prepareVOWAgreementModal(error);
		}

		if (this.errorIsBrokerListListingForbiddenError(error)) {
			// TODO: handle Broker List access here
		}

	}

	get errorClass() {
		const {listingError: error} = this;

		if (!error) {
			return undefined;
		}

		if (this.errorIsVOWError(error)) {
			return "error-type--vow-agreement-required";
		} else if (this.errorIsBrokerListListingForbiddenError(error)) {
			return "error-type--broker-list-listing-forbidden";
		} else if (this.errorIsUnavailableError(error)) {
			return "error-type--listing-unavailable"
		} else {
			return "error-type--other"
		}
	}

	async prepareVOWAgreementModal(error: VowTermsOfUseAgreementRequiredError) {
		const {suggestedAgentId} = error.extensions;

		if (!suggestedAgentId) {
			throw new Error("Missing suggested agent");
		}

		const suggestedAgentResult = await this.$apollo.query<AgentByIdResult, AgentByIdQueryVariables>({
			query: AgentByIdQuery,
			variables: {
				id: suggestedAgentId,
			}
		});

		const agent = suggestedAgentResult.data.agent;

		if (!agent) {
			throw new Error("Error fetching agent");
		}

		this.$modal.show(VowTermsOfUse, {
			agent,
			onAgree: (_event: any) => {
				this.completeVowAgreement(suggestedAgentId);
			},
		}, {
			name: "tou",
			adaptive: true,
			height: "100%",
			width: "100%",
			scrollable: true,
			reset: true,
		});
	}

	async onVowAgreementAgentClose(_event: any) {
		// noop - implement specific behaviour on subclasses
	}

	async completeVowAgreement(agentId: string) {
		await this.$apollo.mutate<ConnectToProvidingAgentResult, ConnectToProvidingAgentVariables>({
			mutation: ConnectToProvidingAgentMutation,
			variables: {
				input: {
					desiredAgentId: agentId
				}
			},
		});

		// since access to listings have changed, the easiest way to invalidate all data (listing or otherwise) is to reset the cache
		// this re-fetches active (visible?) queries, which is nice
		await this.$apollo.getClient().resetStore();

		this.$modal.hide("tou");
	}

	get mapLink(): string | null {
		const {listingError} = this;

		if (
			this.errorIsListingLatLonError(listingError) &&
			listingError.extensions.latLon
		) {
			const {latLon} = listingError.extensions;

			return `https://maps.apple.com/?ll=${latLon.lat},${latLon.lon}&q=${encodeURIComponent('Listing location')}`;
		}

		return null;
	}

	generateMailToLink(contactEmail: string | null, body: string, subject: string) {
		const url = new URL(`mailto:${contactEmail ?? ""}`);

		url.search = [
			`body=${encodeURIComponent(body)}`,
			`subject=${encodeURIComponent(subject)}`
		].join("&");

		const mailto = url.toString();

		return mailto;
	}

	get clientToAgentMailTo(): string | null {
		const {agent, listingError, listing} = this;
		const contactEmail = agent?.contactEmail || null;

		if (!(
			listingError && (
				this.errorIsVOWError(listingError) ||
				this.errorIsUnavailableError(listingError) ||
				this.errorIsBrokerListListingForbiddenError(listingError)
			)
		)) {
			return null;
		}

		let body = `Hi.`;

		body += "\n";
		body += "\n";

		if (this.errorIsVOWError(listingError)) {
			if (listing) {
				body += `I'd like to learn more about this listing`;
			} else {
				body += `This listing is greyed out and I would like to be able to see it. Can you help me with this?`;
			}
		} else if (this.errorIsBrokerListListingForbiddenError(listingError)) {
			body += "I'd like to learn more about this Broker List® listing.";
		} else {
			body += `Can you tell me more about this unavailable listing?`;
		}

		if (this.errorIsListingLatLonError(listingError) && listingError.extensions.listingUrl) {
			body += "\n";
			body += `${listingError.extensions.listingUrl}`;
		} else {
			body += "\n";
			body += `This is the listing reference ID: ${listingError.extensions.listingId}`;
		}

		let {mapLink} = this;

		if (mapLink) {
			body += "\n";
			body += `It's located near: ${mapLink}`;
		}

		const listingUrl = listing?.url;

		if (listingUrl) {
			body += "\n";
			body += `Here's the URL: ${listingUrl}`;
		}


		body += "\n\n";
		body += `Thank you.`;

		return this.generateMailToLink(contactEmail, body, "Can you help me with this listing?");
	}

	get agentToListedMailTo(): string | null {
		const {listingError, listing} = this;
		const contactEmail = "help@listed.inc";

		if (
			!(listingError && (
				this.errorIsVOWError(listingError) ||
				this.errorIsUnavailableError(listingError) ||
				this.errorIsBrokerListListingForbiddenError(listingError)
			))
		) {
			return null;
		}

		let body = `Hi.`;

		body += "\n";
		body += "\n";

		let subject;

		if (this.errorIsVOWError(listingError)) {
			body += `Please help me connect with a local expert agent to get information about this listing for myself and my clients.`;
			subject = "Connection Request";
		} else if (this.errorIsBrokerListListingForbiddenError(listingError)) {
			body += `Please help me opt in to Broker List® to get information about this listing for myself and my clients.`;
			subject = "I'd like to opt in to Broker List®";
		} else {
			body += `Can you tell me more about this listing's status?`;
			subject = "How can I find out more about this unavailable listing?";
		}

		if (this.errorIsListingLatLonError(listingError) && listingError.extensions.listingUrl) {
			body += "\n";
			body += `${listingError.extensions.listingUrl}`;
		} else {
			body += "\n";
			body += `This is the listing reference ID: ${listingError.extensions.listingId}`;
		}

		let {mapLink} = this;

		if (mapLink) {
			body += "\n";
			body += `It's located near: ${mapLink}`;
		}

		const listingUrl = listing?.url;

		if (listingUrl) {
			body += "\n";
			body += `Here's the URL: ${listingUrl}`;
		}


		body += "\n\n";
		body += `Thank you.`;

		return this.generateMailToLink(contactEmail, body, subject);
	}
}
